<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addAccount()" v-if="isAdmin">新增账号</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%" default-expand-all>
        <el-table-column label="姓名" prop="name" />
        <el-table-column label="角色" prop="roleMessage.name" />
        <el-table-column label="创建时间" prop="createTime" />
        <!-- <el-table-column label="操作">
          <template slot-scope="props">
            <div v-if="isAdmin && props.row.role > 1">
              <el-button type="primary" @click="addAccount(props.row)">编辑</el-button>
              <el-button type="danger" @click="deleteData(props.row.id)">删除</el-button>
            </div>
            <div v-if="!isAdmin || props.row.role == 1">
              <el-button type="primary" @click="showUpdatePwd(props.row)">修改密码</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="pwdDialogVisible" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-form-item label="旧密码" >
          <el-input v-model="passwordOld" type="password" placeholder="请输入旧密码" />
        </el-form-item>
        <el-form-item label="新密码" >
          <el-input v-model="newpassword" type="password" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认新密码" >
          <el-input v-model="cPassword" type="password" placeholder="请再次输入新密码" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updatePassword">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="accountDialogVisible" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-form-item label="账号" >
          <el-input v-model="name" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item label="密码" >
          <el-input v-model="password" type="password" />
        </el-form-item>
        <el-form-item label="角色" >
          <el-select v-model="role" >
              <el-option v-for="(item,idx) in roleList" :key="idx" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        
        
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAccount">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {
  data () {
    return {
      passwordOld: '',
      newpassword: '',
      cPassword: '',
      list: [],
      roleList: [],
      role: 0,
      accountDialogVisible: false,
      pwdDialogVisible: false,
      nickName: '',
      name: '',
      currentId: '',
      password: '',
      isAdmin: true
    }
  },
  created () {
    
    const userInfo = window.getUserInfo();
    if(userInfo && userInfo.role > 1){
      this.isAdmin = false;
    }
    if(this.isAdmin){
      this.GetList();
      this.getRoleList();
    }else{
      this.list = [userInfo]
    }
  },
  methods: {
    getRoleList () {
      this.$api.queryRole({currentPage: 1, pageSize: 100}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败')
        
        this.roleList = res.data.records.filter(o => o.id != 1)
        this.role = this.roleList[0].id;

      })
    },
    GetList () {
      this.$api.userList({currentPage: 1, pageSize: 100}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败')
       
        this.list = res.data.records
      })
    },
    deleteData(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteSysUserById({id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    showUpdatePwd(data){
      this.currentId = data.id;
      this.pwdDialogVisible = true;
    },
    updatePassword(){
      const {passwordOld, newpassword, cPassword} = this;
      if(!passwordOld) return this.$message.error('请输入旧密码')
      if(newpassword.length < 6) return this.$message.error('新密码长度不能少于6位')
      if(!newpassword) return this.$message.error('请输入新密码')
      if(!cPassword) return this.$message.error('请再次输入新密码')
      if(cPassword != newpassword) return this.$message.error('两次密码输入不一致')
      
      this.$api.UpdateSysUser({id: this.currentId,password: newpassword, passwordOld}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + res.message)
        this.$message.success('请求成功')
        this.pwdDialogVisible = false;
      })
    },
    // 点击显示出编辑商品对话框
    addAccount (data) {
      this.accountDialogVisible = true
      if(data){
        this.currentId = data.id;
        this.name = data.name;
        this.password = data.password;
        this.role = data.role;
      }else{
        this.currentId = null;
        this.name = '';
        this.password = '';
        this.role = this.roleList[0].id;
      }
    },

    saveAccount () {
      const {name, password, role} = this;
      if(!name) return this.$message.error('请输入账号')
      if(!password) return this.$message.error('请输入密码')

      const params = {name, password, role, status: 1};
      let promise = null;
      if(this.currentId){
        params.id = this.currentId;
        promise = this.$api.UpdateSysUser(params)
      }else{
        params.password = '123456'
        promise = this.$api.userCreate(params)
      }
      promise.then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + res.message)
        this.$message.success('保存成功')
        this.accountDialogVisible = false;
        this.GetList();
      })
    },

    addAddress(data){
      this.currentId = data.id;
      this.dialogVisible = true;
    },
    save () {

      let prov = this.address[this.provIndex].province;
      let city = this.city == '--' ? null : this.city;
      this.$api.AddNoOrderAddress({templateId: this.currentId,prov: prov, city: city, area: this.area}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },
    
  },
}
</script>
<style lang="scss" scoped>
.show-a{
    color: #439fe0;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
.address-row{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 20px;
  &:nth-child(2n){
    background: #f9f9f9;
  }
}
</style>
